import React from 'react';
import './App.css';
import DataTable from './DataTable';
import jsonData from './data.json';

function App() {
    const tableStyle = {
        borderCollapse: 'collapse',
        width: '100%',
        border: '1px solid #ddd',
        fontSize: '18px',
        textAlign: 'center'
    };

    const thTdStyle = {
        padding: '10px',
        borderBottom: '1px solid #ddd'
    };

    return (
        <div className="App">
			<h1 className="page-title"><a href="https://cinedatabase.com">CineDatabase</a></h1>
            <p className="page-description">Database of over 700 top-rated films from around the world</p>
            <DataTable columns={jsonData.columns} data={jsonData.data} tableStyle={tableStyle} cellStyle={thTdStyle} />
			<footer className="footer">
            	<p><a href="mailto:contact@cinedatabase.com">Contact</a></p>
			</footer>
		</div>
    );
}

export default App;
