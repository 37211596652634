import React from 'react';
import { useTable, useFilters, useSortBy, usePagination } from 'react-table';
import './DataTable.css'; // Assurez-vous de créer ce fichier CSS pour le style de la fenêtre contextuelle
import playIcon from './icon.png'; // Remplacez par le chemin vers votre icône "play"


function DataTable({ columns, data, tableStyle, cellStyle }) {
    const [filterInput, setFilterInput] = React.useState("");

    const defaultColumn = React.useMemo(
        () => ({
            filter: (rows, id, filterValue) => {
                const words = filterValue.split(' ');
                return rows.filter(row => {
                    const rowValues = Object.values(row.values);
                    return words.every(word =>
                        rowValues.some(value =>
                            String(value).toLowerCase().includes(String(word).toLowerCase())
                        )
                    );
                });
            }
        }),
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        setFilter,
        canPreviousPage,
		setPageSize,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex, pageSize }
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            initialState: { pageIndex: 0, pageSize: 20 }
        },
        useFilters,
        useSortBy,
        usePagination
    );
	
	const [showVideo, setShowVideo] = React.useState(null);
	

    const handleFilterChange = e => {
        const value = e.target.value || undefined;
        columns.forEach(column => {
            setFilter(column.accessor, value);
        });
        setFilterInput(value);
    };

    return (
        <div>
            <input
                className="search-input"
                value={filterInput}
                onChange={handleFilterChange}
                placeholder={"Search..."}
            />
            <table {...getTableProps()} style={tableStyle}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())} style={cellStyle}>
                                    {column.render('Header')}
                                    <span>
                                        {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
			<tbody {...getTableBodyProps()}>
			        {page.map(row => {
			            prepareRow(row);
			            return (
			                <tr {...row.getRowProps()}>
			                    {row.cells.map(cell => {
			                        if (cell.column.Header === "Film Name" || cell.column.Header === "filmName") {
			                            return (
										<td {...cell.getCellProps()} style={cellStyle}>
										    <div className="film-title">{cell.render('Cell')}</div>
										    {cell.row.original.trailerUrl && (
										        <span 
										            className="play-icon" 
										            onMouseOver={() => {
										                setShowVideo(cell.row.original.trailerUrl);
										            }}
										        >
										            <img src={playIcon} alt="Play Trailer" />
										            {showVideo === cell.row.original.trailerUrl && (
										                <div 
										                    className="trailer-popup" 
										                    onMouseLeave={() => setShowVideo(null)}
										                >
										                    <iframe 
										                        width="560" 
										                        height="315" 
										                        src={showVideo} 
										                        frameBorder="0" 
										                        allowFullscreen
										                    ></iframe>
										                </div>
										            )}
										        </span>
										    )}
										</td>
			                            );
			                        }
			                        return <td {...cell.getCellProps()} style={cellStyle}>{cell.render('Cell')}</td>;
			                    })}
			                </tr>
			            );
			        })}
			    </tbody>
            </table>
            <div className="pagination">
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    {'<<'}
                </button>{' '}
                <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {'<'}
                </button>{' '}
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                    {'>'}
                </button>{' '}
                <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                    {'>>'}
                </button>{' '}
                <span>
                    Page{' '}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>{' '}
                </span>
                <span>
                    | Go to page:{' '}
                    <input
                        type="number"
                        defaultValue={pageIndex + 1}
                        onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0;
                            gotoPage(page);
                        }}
                        style={{ width: '50px' }}
                    />
                </span>{' '}
                <select
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value));
                    }}
                >
                    {[20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
}

export default DataTable;
